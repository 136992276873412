import React, { ReactNode } from 'react'
import Link from 'next/link'
import { LogoDark } from '../../icons/LogoDark'

const Layout = ({ children }: { children: ReactNode }) => (
  <div className='max-w-screen-2xl mx-auto flex flex-col h-full min-h-screen bg-gray-50 md:flex-row'>
    <div className='relative flex flex-col items-start flex-1'>
      <Link
        href='/'
        passHref
        className='absolute px-6 sm:px-8 pt-5 sm:pt-6 transition-opacity hover:opacity-70 focus:opacity-70'
        aria-label='Home'
      >
        <LogoDark width={138} height={38} className='h-12 lg:h-16' />
      </Link>
      {children}
    </div>
  </div>
)

export default Layout
