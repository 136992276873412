export function GitHub(props) {
  return (
    <svg width={'1em'} viewBox='0 0 34 33' fill='none' {...props}>
      <path
        d='M31.286 8.621a16.427 16.427 0 00-6.004-6.005C22.754 1.141 19.994.403 17 .403c-2.994 0-5.755.738-8.282 2.213a16.426 16.426 0 00-6.005 6.005C1.238 11.149.5 13.909.5 16.903c0 3.595 1.049 6.828 3.147 9.7 2.099 2.871 4.81 4.858 8.132 5.961.387.072.673.022.86-.15a.84.84 0 00.278-.644l-.01-1.16a191 191 0 01-.011-1.912l-.494.085a6.298 6.298 0 01-1.193.076 9.08 9.08 0 01-1.493-.15 3.337 3.337 0 01-1.44-.645 2.726 2.726 0 01-.944-1.32l-.215-.495c-.143-.33-.369-.695-.677-1.096-.308-.401-.62-.673-.934-.816l-.15-.108a1.577 1.577 0 01-.28-.258c-.086-.1-.15-.2-.193-.3-.043-.101-.008-.183.107-.248.115-.065.322-.096.623-.096l.43.064c.286.058.64.229 1.063.516.423.286.77.658 1.042 1.116.33.588.727 1.035 1.192 1.343.466.308.935.462 1.407.462.473 0 .881-.036 1.225-.107.344-.072.666-.18.967-.322.129-.96.48-1.698 1.052-2.213a14.709 14.709 0 01-2.202-.387 8.766 8.766 0 01-2.019-.838 5.782 5.782 0 01-1.73-1.44c-.458-.573-.834-1.325-1.127-2.256-.294-.93-.44-2.005-.44-3.222 0-1.733.565-3.208 1.697-4.426-.53-1.303-.48-2.764.15-4.382.415-.13 1.031-.033 1.848.29.816.322 1.414.598 1.793.827.38.229.684.423.914.58A15.256 15.256 0 0117 8.373c1.417 0 2.793.187 4.125.559l.816-.516a11.561 11.561 0 011.976-.945c.76-.286 1.34-.365 1.741-.236.644 1.618.702 3.08.172 4.382 1.131 1.218 1.697 2.693 1.697 4.426 0 1.218-.147 2.295-.44 3.233-.294.939-.673 1.69-1.138 2.256a6.007 6.007 0 01-1.74 1.429 8.787 8.787 0 01-2.02.838c-.652.172-1.386.301-2.203.387.745.644 1.117 1.661 1.117 3.05v4.533c0 .258.09.473.27.644.178.172.46.222.848.15 3.323-1.102 6.033-3.09 8.131-5.961 2.098-2.872 3.148-6.105 3.148-9.7-.001-2.993-.739-5.753-2.214-8.281z'
        fill='currentColor'
      />
    </svg>
  )
}
