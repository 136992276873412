import { useUserContext } from '../UserProvider'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { REDIRECT_AFTER_LOGIN } from '../../utils/constants'

const useSignin = () => {
  const { user } = useUserContext()
  const router = useRouter()

  useEffect(() => {
    if (!user) {
      return
    }

    if (
      router.query.redirect &&
      typeof router.query.redirect === 'string' &&
      new URL(router.query.redirect, location.href).origin === location.origin
    ) {
      router.replace(router.query.redirect)
      return
    }

    const redirectAfterLogin = localStorage?.getItem(REDIRECT_AFTER_LOGIN)
    if (redirectAfterLogin) {
      localStorage.removeItem(REDIRECT_AFTER_LOGIN)
      router.replace(REDIRECT_AFTER_LOGIN)
    }

    router.push('/app')
  }, [user, router])

  return user
}

export default useSignin
