import { useForm } from 'react-hook-form'
import { Input } from '../../designsystem/Input'
import { emailRegex } from '../../utils/validation'
import { Button } from '../../designsystem/Button'
import Mail from '../../icons/Mail'
import React from 'react'

const EmailForm = (props: { redirect: string | null }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<{ email: string }>()

  const values = watch()

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const redirect = props.redirect
          ? `&redirect=${encodeURIComponent(props.redirect)}`
          : ''
        window.location.href = `/api/auth/email/login?email=${encodeURIComponent(
          data.email,
        )}${redirect}`
      })}
    >
      <Input
        className='max-w-full'
        id='email'
        placeholder='Email Address'
        type='text'
        {...register('email', {
          required: 'Please enter an email',
          pattern: {
            message: "Looks like that isn't a valid email",
            value: emailRegex,
          },
        })}
        error={errors.email?.message}
      />

      <Button
        secondary={!values.email || values.email.length === 0}
        className='xs:mt-4 xs:mb-8 !w-full justify-center'
      >
        <Mail className='h-6 mr-3 ' />
        Continue with Email
      </Button>
    </form>
  )
}

export default EmailForm
