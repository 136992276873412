import React from 'react'

const Button = ({ className, ...props }: JSX.IntrinsicElements['a']) => (
  <a
    className={`oauth-button mt-4 flex items-center justify-center rounded-md font-medium text-lg px-5 py-3 text-white focus:outline-none focus:ring ring-gray-500 ${
      className || ''
    }`}
    {...props}
  />
)

export default Button
