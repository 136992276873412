import React from 'react'
import { useRouter } from 'next/router'
import { GitHub } from 'icons/GitHub'
import { GitLab } from 'icons/GitLab'
import Meta from 'components/Meta'
import Link from 'next/link'
import { H1, SmallItem, SubHeadline } from 'designsystem/Text'
import { GetServerSideProps } from 'next'
import Layout from '../../components/login/Layout'
import EmailForm from '../../components/login/EmailForm'
import useSignin from '../../components/login/useSignin'
import Button from '../../components/login/Button'

type PageProps = { redirect: string | null }

export const getServerSideProps: GetServerSideProps<PageProps> = async ({
  query,
}) => {
  return {
    props: {
      redirect: Array.isArray(query.redirect)
        ? query.redirect[0]
        : query.redirect || null,
    },
  }
}

export default function Signin(props: PageProps): JSX.Element | null {
  const router = useRouter()
  const user = useSignin()

  // Don't render while redirecting
  if (user) {
    return null
  }

  const search = props.redirect
    ? `?redirect=${encodeURIComponent(props.redirect)}`
    : ''

  return (
    <Layout>
      <Meta
        title='Login – Stellate'
        description='Log into Stellate, the GraphQL CDN, with your GitHub account.'
      />
      <div className='flex items-center justify-center flex-1 w-full mt-10'>
        <div className='w-full max-w-lg px-3 py-24 text-center sm:px-6'>
          <SubHeadline>Stellate Login</SubHeadline>
          <H1 className='text-left sm:text-center'>Welcome back!</H1>
          <div className='mt-10 mb-32 text-center max-w-xs mx-auto flex flex-col'>
            <Button className='github' href={`/api/auth/github${search}`}>
              <GitHub />
              Login with GitHub
            </Button>

            <Button className='gitlab' href={`/api/auth/gitlab/login${search}`}>
              <GitLab />
              Login with GitLab
            </Button>
            <SmallItem className='my-8'>or</SmallItem>
            <EmailForm {...props} />

            <SmallItem className='mt-4 text-left sm:text-center'>
              Not registered yet?{' '}
              <Link
                href={{ pathname: `/signup`, query: router.query }}
                legacyBehavior
                passHref
              >
                <a className='underline'>Create your account</a>
              </Link>
            </SmallItem>
          </div>
          <SmallItem className='max-w-lg text-left sm:text-center'>
            Stellate only stores your email, name and avatar. By signing up you
            agree to the{' '}
            <Link passHref legacyBehavior href='/terms'>
              <a className='underline'>Terms of Service</a>
            </Link>{' '}
            and{' '}
            <Link passHref legacyBehavior href='/privacy'>
              <a className='underline'>Privacy Policy</a>
            </Link>
            .
          </SmallItem>
        </div>
      </div>
    </Layout>
  )
}
